import { tv } from 'tailwind-variants';

export const triggerClassName = tv({
  base: 'h-5 w-5 hover:stroke-purpleBlue-600 active:stroke-purpleBlue-700',
  variants: {
    disabled: {
      true: 'cursor-not-allowed stroke-blueGray-300 hover:stroke-blueGray-300 active:stroke-blueGray-300',
    },
  },
});

export const inputClassName = tv({
  base: 'pl-9',
});
