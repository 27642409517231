import { InputHTMLAttributes, Ref, forwardRef } from 'react';
import { PhoneInput } from 'react-international-phone';

import 'react-international-phone/style.css';
import { inputFieldClasses } from '../InputField/styles';

const InputPhone = forwardRef<HTMLInputElement, IPhoneInputProps>(
  ({ value, onChange, ...props }, ref) => {
    const handleChange = (phone: string) => {
      onChange?.(phone);
    };

    return (
      <PhoneInput
        countrySelectorStyleProps={{
          buttonStyle: {
            height: '40px',
            backgroundColor: '#F3F4F6',
            borderWidth: '0px',
            borderRight: 'none',
            marginRight: '1px',
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
          },
        }}
        value={String(value)}
        dialCodePreviewStyleProps={{
          style: {
            backgroundColor: '#F3F4F6',
            borderWidth: '0px',
            borderLeft: 'none',
            marginLeft: 'px',
            display: 'flex',
            fontSize: '14px',
            alignItems: 'center',
            justifyContent: 'center',
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px',
          },
        }}
        onChange={handleChange}
        inputProps={
          {
            ...props,
            ref,

            className: inputFieldClasses({ className: 'rounded-l-none' }),
          } as InputHTMLAttributes<HTMLInputElement> & {
            ref: Ref<HTMLInputElement>;
          }
        }
      />
    );
  },
);

InputPhone.displayName = 'InputPhone';

export { InputPhone };
