'use client';

import { autoPlacement, autoUpdate, useFloating } from '@floating-ui/react';
import { Menu, Portal } from '@headlessui/react';
import { forwardRef, useCallback, useEffect, useState } from 'react';

import { IconChevronDown } from '@/icons/IconChevronDown';
import { IconInfo } from '@/icons/IconInfo';
import { IconPlus } from '@/icons/IconPlus';

import {
  classNameSpamItem,
  classes,
  dropdownButtonClasses,
  menuItemClassName,
} from './styles';

const Dropdown = forwardRef<HTMLButtonElement, IDropdownProps>(
  (
    {
      id = 'dropdown-button-menu',
      placeholder,
      items,
      className,
      classNameItem,
      contentWidth,
      value = null,
      defaultValue = null,
      disabled = false,
      footer,
      onClick,
    },
    ref,
  ) => {
    const { refs, floatingStyles } = useFloating({
      whileElementsMounted: autoUpdate,
      middleware: [
        autoPlacement({
          allowedPlacements: ['top', 'bottom'],
        }),
      ],
    });

    const [buttonWidth, setButtonWidth] = useState(0);

    const buttonRef = useCallback(
      (node: HTMLButtonElement | null) => {
        if (node !== null) {
          setButtonWidth(node.getBoundingClientRect().width);
          refs.setReference(node);
        }
      },
      [refs],
    );

    useEffect(() => {
      if (refs.reference.current) {
        setButtonWidth(refs.reference.current.getBoundingClientRect().width);
      }
    }, [refs.reference]);

    return (
      <Menu
        ref={ref}
        tabIndex={disabled ? -1 : 0}
        as='div'
        className={`${classes({ className, disabled })}`}
      >
        <Menu.Button
          id={id}
          ref={buttonRef}
          disabled={disabled}
          className={dropdownButtonClasses({ disabled })}
        >
          {value?.title || defaultValue || (
            <span className='text-blueGray-300'>{placeholder}</span>
          )}
          <IconChevronDown />
        </Menu.Button>
        <Portal
          as={Menu.Items}
          ref={refs.setFloating}
          style={{
            ...floatingStyles,
            width: contentWidth,
            minWidth: buttonWidth,
          }}
          className={menuItemClassName()}
        >
          {items.map(item => (
            <Menu.Item
              as='div'
              onClick={() => onClick?.(item)}
              className='flex cursor-pointer'
              key={item.id as string}
            >
              {({ active }) =>
                item.disabled ? (
                  <span className='opacity-75'>{item.title}</span>
                ) : (
                  <span
                    className={classNameSpamItem({
                      active,
                      className: classNameItem,
                    })}
                  >
                    <a
                      className='w-full text-left text-sm font-semibold text-blueGray-600'
                      href={item.href}
                    >
                      {item.title}
                    </a>

                    {item.description !== undefined &&
                      item.description.length && (
                        <span className='text-left text-xs'>
                          {item.description}
                        </span>
                      )}
                  </span>
                )
              }
            </Menu.Item>
          ))}

          {items.length === 0 && (
            <Menu.Item>
              <span className='flex w-full cursor-pointer items-center p-2 text-left text-xs font-semibold text-blueGray-600'>
                No items found
              </span>
            </Menu.Item>
          )}

          {footer && (
            <Menu.Item>
              {!footer.onClick ? (
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  className='flex w-full cursor-pointer items-center p-2 text-left text-xs font-semibold text-blueGray-600 hover:underline'
                  href={footer?.href}
                >
                  <IconInfo className='mr-1 h-4' />{' '}
                  <span> {footer?.title}</span>
                </a>
              ) : (
                <span
                  onClick={footer.onClick}
                  className='flex w-full justify-center cursor-pointer items-center p-2 pb-0 text-left text-xs font-semibold text-blueGray-600 hover:underline'
                >
                  <IconPlus className='mr-1 w-5 h-5' />
                  <span> {footer?.title}</span>
                </span>
              )}
            </Menu.Item>
          )}
        </Portal>
      </Menu>
    );
  },
);

Dropdown.displayName = 'Dropdown';
export { Dropdown };
