import { forwardRef } from 'react';

import { formatCurrencyDynamically } from '@/utils/FormatCurrencyDinamically';

import { InputField } from '../InputField';

import { classesCurrencyField, inputCurrencyLabelClasses } from './styles';

export interface InputCurrencyProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const InputCurrency = forwardRef<HTMLInputElement, InputCurrencyProps>(
  ({ className, onChange, ...props }, ref) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = formatCurrencyDynamically(e.target.value);
      e.target.value = value;
      onChange?.(e);
    };
    return (
      <div className='relative'>
        <div className='pointer-events-none absolute inset-y-0 left-0 top-0.5 flex items-center pl-3'>
          <span
            className={inputCurrencyLabelClasses({ disabled: props.disabled })}
          >
            $
          </span>
        </div>
        <InputField
          {...props}
          className={classesCurrencyField({ className })}
          ref={ref}
          onChange={handleChange}
        />
      </div>
    );
  },
);

InputCurrency.displayName = 'InputCurrency';

export { InputCurrency };
