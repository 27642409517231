import React, { forwardRef } from 'react';

import { classes } from './styles';

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const TextAreaField = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, ...props }, ref) => (
    <textarea className={classes({ className })} ref={ref} {...props} />
  ),
);

TextAreaField.displayName = 'TextArea';

export { TextAreaField };
