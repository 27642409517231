'use client';

import { format } from 'date-fns';
import { ChangeEvent, forwardRef, useCallback, useState } from 'react';

import { DatePickerPopover } from '@/app/components/DatePicker';
import { inputClassName } from '@/app/components/Input/InputDate/styles';
import { InputField } from '@/app/components/Input/InputField';

export const InputDate = forwardRef<HTMLInputElement, TInputDateFieldProps>(
  ({ className, defaultValue, ...props }, ref) => {
    const { onChange } = props;
    const [pickedDate, setPickedDate] = useState<string>();

    const onChangeFormattedDate = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        let input = e.target.value;
        input = input.replace(/\D/g, '');

        if (input.length > 8) {
          input = input.slice(0, 8);
        }

        if (input.length > 2) {
          input = input.replace(/(\d{2})(\d)/, '$1/$2');
        }

        if (input.length > 4) {
          input = input.replace(/(\d{2})\/(\d{2})(\d)/, '$1/$2/$3');
        }

        setPickedDate(input as string);

        e.target.value = input;
        onChange?.(e);
      },
      [onChange],
    );

    const onChangeDate = (date?: Date) => {
      const formattedDate = date ? format(date, 'MM/dd/yyyy') : '';
      const event = {
        target: {
          value: formattedDate,
          name: props.name,
        },
      } as ChangeEvent<HTMLInputElement>;

      setPickedDate(formattedDate);
      onChange?.(event as ChangeEvent<HTMLInputElement>);
    };

    return (
      <div className='relative'>
        <div className='absolute left-2.5 top-2.5'>
          <DatePickerPopover
            disabled={props.disabled}
            onSelectedDate={onChangeDate}
          />
        </div>
        <InputField
          {...props}
          ref={ref}
          inputMode='numeric'
          className={inputClassName({
            className,
          })}
          onChange={onChangeFormattedDate}
          value={pickedDate}
        />
      </div>
    );
  },
);

InputDate.displayName = 'InputDate';
