import { iconClasses } from '../styles';

export function IconChevronDown({ className }: IconProps) {
  return (
    <svg
      className={iconClasses({ className })}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 10L12 14L16 10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
