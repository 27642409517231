import { Text } from '@/app/components/Typography';

import { inputClasses } from './styles';

export function InputDescription({
  children,
  className,
  error,
}: IInputDescriptionProps) {
  return <Text className={inputClasses({ className, error })}>{children}</Text>;
}
