'use client';

import { useFormContext } from 'react-hook-form';

import { InputDescription } from '../InputDescription';

interface InputErrorProps extends Omit<IInputDescriptionProps, 'children'> {
  fieldName: string;
}

export function InputError({ ...props }: InputErrorProps) {
  const context = useFormContext();

  if (!context) {
    throw new Error('InputError must be used within a FormProvider');
  }

  const { formState } = context;

  const getErrorMessage = (
    fieldName: string,
    errors: any,
  ): string | undefined => {
    const [first, ...rest] = fieldName.split('.');

    if (!errors || !errors[first]) {
      return undefined;
    }

    if (rest.length === 0) {
      return errors[first]?.message;
    }

    return getErrorMessage(rest.join('.'), errors[first]);
  };

  const errorMessage = getErrorMessage(props.fieldName, formState.errors);

  return (
    <InputDescription
      {...props}
      error
      className={!errorMessage ? 'invisible' : ''}
    >
      {errorMessage}
    </InputDescription>
  );
}
