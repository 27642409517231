import { iconClasses } from '../styles';

function IconCalendar({ className, ...props }: IconProps) {
  return (
    <svg
      className={iconClasses({ className })}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M7.5 3V6' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.5 3V6' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.5 11.5H7.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M8.5 15H7.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.5 11.5H11.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M12.5 15H11.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.5 11.5H15.5' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16.5 15H15.5' strokeLinecap='round' strokeLinejoin='round' />
      <rect
        x='3'
        y='4.5'
        width='18'
        height='16.5'
        rx='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export { IconCalendar };
