import { tv } from 'tailwind-variants';

import { inputFieldClasses } from '@/app/components/Input/InputField/styles';

export const classes = tv({
  extend: inputFieldClasses,
  base: `group-focus:border-none relative group-focus:outline-none group-focus:ring-2 group-focus:ring-offset-2 group-focus:ring-purpleBlue-600
  focus:border-none focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purpleBlue-600
  `,
  variants: {
    disabled: {
      true: 'cursor-not-allowed border-gray-200 bg-gray-100 hover:border-gray-200 focus:border-gray-300 focus:ring-transparent active:ring-transparent',
    },
  },
});

export const dropdownButtonClasses = tv({
  base: 'flex w-full items-center justify-between group truncate text-blueGray-700',
  variants: {
    disabled: {
      true: 'cursor-not-allowed opacity-60',
    },
  },
});

export const menuItemClassName = tv({
  base: 'w-max max-h-[250px] first-letter: z-[350] overflow-auto divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none absolute left-0 mt-2 py-2  roundedring-1 ring-black ring-opacity-5 focus:outline-none',
  variants: {
    adptativeWidth: {
      true: 'w-full',
      false: 'max-w-[256px]',
    },
  },
});

export const classNameSpamItem = tv({
  base: 'flex flex-col min-w-full px-3 py-1',
  variants: {
    active: {
      true: 'bg-blueGray-100',
    },
  },
});
