'use client';

import { forwardRef, useState } from 'react';

import { EyeHidden } from '@/icons/EyeHidden';
import { EyeVisible } from '@/icons/EyeVisible';

import { InputField } from '../InputField';

export interface InputPasswordProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {}

const InputPassword = forwardRef<HTMLInputElement, InputPasswordProps>(
  ({ className, ...props }, ref) => {
    const [isVisible, setIsVisible] = useState(false);

    const toggle = () => setIsVisible(prev => !prev);

    return (
      <div className='relative h-max w-full'>
        <InputField
          type={isVisible ? 'text' : 'password'}
          className={`pr-8 ${className}`}
          ref={ref}
          {...props}
        />

        <button
          type='button'
          className='absolute inset-y-1/2 right-2 top-0 translate-y-[50%]'
          onClick={toggle}
        >
          {isVisible ? (
            <EyeVisible className='h-5 stroke-blueGray-600' />
          ) : (
            <EyeHidden className='h-5 stroke-blueGray-600' />
          )}
        </button>
      </div>
    );
  },
);

InputPassword.displayName = 'InputPassword';

export { InputPassword };
