import { InputDate } from '@/app/components/Input/InputDate';

import { InputCurrency } from './InputCurrency';
import { InputDescription } from './InputDescription';
import { Dropdown } from './InputDropdown';
import { InputError } from './InputError';
import { InputField } from './InputField';
import { InputLabel } from './InputLabel';
import { InputPassword } from './InputPassword';
import { InputPhone } from './InputPhone';
import { InputRoot } from './InputRoot';
import { InputSearch } from './InputSearch';
import { TextAreaField } from './Textarea';

const Input = {
  Root: InputRoot,
  Field: InputField,
  Label: InputLabel,
  Group: InputRoot,
  Dropdown,
  Date: InputDate,
  Search: InputSearch,
  Textarea: TextAreaField,
  Password: InputPassword,
  Phone: InputPhone,
  Currency: InputCurrency,
  Description: InputDescription,
  Error: InputError,
};

export default Input;
