import { tv } from 'tailwind-variants';

import { iconClasses } from '../styles';

const classes = tv({ base: '-rotate-180' });

function IconChevronLeft({ className, ...props }: IconProps) {
  return (
    <svg
      className={iconClasses({ className: classes({ className }) })}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.505 3L16.5 12.027L7.5 21'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export { IconChevronLeft };
