'use client';

import { useState } from 'react';

import { calendarPopoverClasses } from '@/app/components/DatePicker/styles';
import { triggerClassName } from '@/app/components/Input/InputDate/styles';
import { IconCalendar } from '@/icons/IconCalendar';

import { Calendar } from '../Calendar';
import Popover from '../Popover';

export function DatePickerPopover({
  onSelectedDate,
  className,
  disabled,
}: IDatePickerDemoProps) {
  const [date, setDate] = useState<Date>();
  const [open, setOpen] = useState(false);

  const handleSelect = (date?: Date) => {
    setDate(date);

    onSelectedDate?.(date);
    setOpen(false);
  };

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger disabled={disabled}>
        <IconCalendar
          className={triggerClassName({
            disabled,
          })}
        />
      </Popover.Trigger>
      <Popover.Content
        alignOffset={-6}
        sideOffset={10}
        align='start'
        className={calendarPopoverClasses({
          className,
        })}
      >
        <Popover.Close asChild>
          <Calendar
            mode='single'
            className='p-3'
            selected={date}
            onSelect={handleSelect}
            initialFocus
          />
        </Popover.Close>
      </Popover.Content>
    </Popover.Root>
  );
}
