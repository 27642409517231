import { tv } from 'tailwind-variants';

import { inputFieldClasses } from '../InputField/styles';

export const classesCurrencyField = tv({
  extend: inputFieldClasses,
  base: '!pl-7',
});

export const inputCurrencyLabelClasses = tv({
  base: 'text-gray-500 sm:text-sm',
  variants: {
    disabled: {
      true: 'text-blueGray-300',
    },
  },
});
